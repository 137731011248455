<template>
  <div>
    <v-form
      class="d-flex flex-wrap justify-space-between  border border-b"
      ref="form"
      v-model="formValid"
      @submit.prevent="getOrderCount()"
    >
      <div class="ma-0 pa-4 align-center">
        <div class="d-flex pa-0">
         
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @click:append="dateMenu = true"
                clearable
                class="apiKeyInput"
                v-model="computedDateFormatted"
                outlined
                persistent-hint
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="fromDateRules"
                placeholder="Select the From Date"
                append-icon="mdi-calendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="new Date().toISOString().substr(0, 10)"
              v-model="date"
              :show-current="true"
              no-title
              @input="dateMenu = false"
              type="month"
            ></v-date-picker>
          </v-menu>
          <div >
          <div class="px-5">
            <v-btn
              height="40"
              type="submit"
              depressed
              class="text-capitalize fsize12 mx-4"
              color="primary"
              >Submit
            </v-btn>
          </div>
        </div>
        </div>
       
       
      </div>
      <div class="pa-4 align-center" v-if="userOrderCount.length > 0 ">
        <div class="d-flex pa-0"> <v-text-field v-click-outside="resetSearch" dense hide-details class="px-3" outlined height="10"  prepend-inner-icon="mdi-magnify" placeholder="Search" v-model="search"></v-text-field>

<v-btn height="32" @click="downloadUnicodeCSV(userOrderCount)" depressed min-width="fit-content" color="primary"
  class="text-capitalize">
  Download <v-icon class="mx-2 fsize18">mdi-cloud-download </v-icon>
</v-btn></div>
       
      </div>

      
    </v-form>
   <div >
    <div class="px-4 pb-4 d-flex flex-wrap justify-space-between">
        <div>
          <v-slide-group v-model="tabSelect" mandatory>
            <v-slide-item v-for="n in  ocTabs" :key="n" v-slot="{ active, toggle }" :value="n">
              <span @click="$router.push({ path: 'reports', query: { tab: n } }).catch(() => { })">
                <v-btn :title="n" depressed height="26" class="fsize12 text-capitalize mr-2" @click="toggle"
                  :color="active ? 'primary' : 'grey lighten-3'">{{ n }}</v-btn>
              </span>
            </v-slide-item>
          </v-slide-group>
        </div>
      </div>
      <div v-if="!loader">
        <v-simple-table  class="mx-4 mb-4 border-top " v-if="totalOrderCount != '' && tabSelect=='Total Orders'">
      <thead>
        <tr>
         
          <th class="text-center max-w-50">Type</th>
          
          <th class="text-center" >Count</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr >
          <td class="text-center max-w-50 font-bold">Total Orders</td>
          <td class="text-center">{{totalOrderCount.orderCount}}</td>
          <td></td>


        </tr>
       
      </tbody>

    </v-simple-table>
    <v-data-table id="oc_tableData" v-if="userOrderCount.length > 0 && tabSelect == 'User Orders'" :headers="titlename" :items="filterUser()" :items-per-page="15"
      class="elevation-1"></v-data-table>
    <div v-if="userOrderCount.length <= 0  && tabSelect == 'User Orders'" class="d-flex align-center justify-center min-height-100 mt-16">
      <div class="text-center">No Records Found</div>
    </div></div></div>
   
   
  </div>
</template>
<script>
import {  mapState } from "vuex";
import commonFunctions from "../../mixins/commonFunctions";
import { reset } from "echarts/lib/visual/seriesColor";
export default {
  name: "orderCount",
  data: ()=> ({
    dateMenu: false,
    fromDateRules: [(v) => !!v || "Date is required"],
    date: new Date().toISOString().substr(0, 10),
    selectedMonth: null,
    selectedYear: null,
    ocTabs: ['Total Orders', 'User Orders'],
    tabSelect: 'Total Orders',
search: '',
formValid: false,
titlename: [
{ text: 'User', align: 'center', sortable: false, value: 'userId', },
{ text: 'Count', align: 'center', sortable: false, value: 'count', },
],

  }),
  mixins: [
    commonFunctions
  ],
 
  computed: {
    ...mapState('orderCount', [, 'totalOrderCount', 'userOrderCount']),
    ...mapState(['loader']),
    computedDateFormatted: {
      get() {
        return this.formatDate(this.date);
      },
      set() { },
    },
  },
  created() {
    
     
      const [year, month, day] = this.date.split("-");
      this.selectedMonth = month;
      this.selectedYear = year; 
      if(this.selectedMonth && this.selectedYear) {
        let json = {
      month: Number(this.selectedMonth).toString(),
      year: Number(this.selectedYear).toString()
    }
    this.$store.dispatch('orderCount/getTotalOrderCount', json);
    this.$store.dispatch('orderCount/getUserOrderCount',json )
      }
    },
  methods: { 
    resetSearch(){
      this.search = ''
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.selectedMonth = month;
      this.selectedYear = year;
    let monthName = new Date(2000, Number(month) - 1).toLocaleString('default', { month: 'long' });
    
      return `${monthName} - ${year}`;
    },
    getOrderCount() {
    // console.log(this.selectedMonth, this.selectedYear);
    let json = {
      month: Number(this.selectedMonth).toString(),
      year: Number(this.selectedYear).toString()
    }
    this.$store.dispatch('orderCount/getTotalOrderCount', json);
    this.$store.dispatch('orderCount/getUserOrderCount',json )
    },
   
    filterUser() {
      return this.userOrderCount.filter((post) => {
        if (post.userId)
          return post.userId.includes(this.search);

        
        return post;
      });
    }
  }
};
</script>
<style>
.max-w-50 {
  max-width: 50% !important;
}
.border-top {
  border-top: 0.5px solid #ccc; /* Example border style */
  border-bottom:0.5px solid #ccc; ;
}
</style>