<template>
  <div>

    <div class="px-2 fsize14 mb-8">
            <div class="pb-1 pt-2 fsize14 font-weight-bold primaryColor pb-4">Check Bank Update Status <v-progress-circular v-if="statusLoader" size="18" :width="2" color="black"
      indeterminate
      
    ></v-progress-circular></div> 
             <v-row class="ma-0 pa-0">
                <v-col class="col-1 pa-0 ma-0">

                </v-col>
                <v-col class="col-2 pa-0 ma-0">
                    <v-checkbox v-model="isCheck" label="Check" > </v-checkbox>
                </v-col>
                <v-col class="col-7 pa-0 ma-0  align-center">
            <div class="pb-1" v-if="isCheck && !statusLoader">BankDetailsCount: <span class="pl-3">{{bankupdateStatus.BankDetailsCount ? bankupdateStatus.BankDetailsCount : 'NA'}}</span></div> 
                    <div v-if="isCheck && !statusLoader" class="pb-1 mt-2">Last Updated Time: <span class="pl-1">{{bankupdateStatus.lastUpdatedTime ? bankupdateStatus.lastUpdatedTime : 'NA'}}</span></div>
                </v-col>
            </v-row>
          </div>
 <div class="px-2 fsize14">
    <div class="fsize14 font-weight-bold primaryColor pb-4">Bank Details</div>
            <v-form ref="bankform" @submit.prevent="callBankDetils">
                <v-row class="ma-0 pa-0">
                <v-col class="col-1 pa-0 ma-0"></v-col>
                <v-col class="col-3 pa-0 ma-0">
                    <div class="pb-1">User Id</div>
                     <v-text-field v-model="userId" class="" :rules="userIdRules" dense outlined menu-props="offset-y" placeholder="Enter the UserId"></v-text-field>
                </v-col>
                <v-col class="col-6 pa-0 ma-0 d-flex align-center">
<v-btn depressed width="120" height="40" type="submit" color="primary text-capitalize ml-2" :loading="bankLoader" :disabled="bankLoader">Submit</v-btn>
                </v-col>
            </v-row>
            </v-form>
             <v-simple-table class="mx-4 mt-4 pb-4" v-if="this.bankdetailsList.length > 0" >
      <thead>
        <tr>
          <th class="text-center" width="100px">Bank Code</th>
          <th class="text-center">Acc No</th>
          <th class="text-center" >MICR Code</th>
          <th class="text-center" >IFSC Code</th>
        </tr>
      </thead>
      <tbody>
       <tr v-for="(i, index) in this.bankdetailsList" :key="index">
         <td class="text-center" width="100px">{{i.bankCode}}</td>
          <td class="text-center">{{i.accNum}}</td>
          <td class="text-center" >{{i.micrCode}}</td>
          <td class="text-center" >{{i.ifscCode}}</td>
       </tr>
      </tbody>

    </v-simple-table>
          </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
    data(){
        return {
            userId: "",
            userIdRules: [(v) => !!v || "User Id is required"],
           isCheck: false
        }
    },
    computed:{
        ...mapState("bankdetails" , ["bankdetailsList", "bankLoader" , 'statusLoader' , 'bankupdateStatus'])
    },
    methods:{
        callBankDetils(){
            if(this.$refs.bankform.validate()){
                let json ={
                    user_id: this.userId,
                }
                this.$store.dispatch('bankdetails/getBankDetailsList', json)
            }
        }
    },
    watch:{
        isCheck: function(val){
            if(val){
                this.$store.dispatch('bankdetails/getBankUpdateStatus')
            }
        }
    }
}
</script>

<style>

</style>