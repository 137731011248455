<template>
  <div class="ma-4">
    <div class="d-flex justify-space-between">
      <v-breadcrumbs class="pa-0 mr-5" :items="titles" large></v-breadcrumbs>
      <v-btn @click="addOrEditSector" depressed color="primary" class="text-capitalize fsize12">Add Sector Details
      </v-btn>
    </div>
    <v-card class="my-4  cardBoxShadow">
      <v-simple-table v-if="sectorMappedList.length != 0 && !loader">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Stock Name</th>
            <th>Scrip Name</th>
            <th>Company Name</th>
            <th>Exchange</th>
            <th>Token</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in sectorMappedList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.stockName }}</td>
            <td>{{ item.scripName }}</td>
            <td>{{ item.companyName }}</td>
            <td>{{ item.exch }}</td>
            <td>{{ item.token }}</td>
            <td class="text-center">
              <v-icon @click="deleteSectorMapping(item)">mdi-delete</v-icon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else-if="!loader" class="d-flex align-center justify-center min-height-100">
        <div>
          <div class="">Data Not Found</div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    titles: [
      { text: "All Sectors", href: "content?tab=Sector", disabled: false },
      { text: "content", disabled: false },
    ],
  }),

  computed: {
    ...mapGetters({
      loader: "getLoader",
    }),
    ...mapGetters("sector", {
      sectorMappedList: "getSectorMappedData",
    }),
  },

  created() {
    this.getMappedData();
  },

  methods: {
    addOrEditSector(sectorId) {
      this.$router.push("/addOrEditSectorContent");
    },
    async deleteSectorMapping(val) {
      let jsonObj = {
        sectorId: val.sectorId,
        token: val.token,
      };
      await this.$store.dispatch("sector/deleteSectorMapping", jsonObj);
      this.getMappedData();
    },
    getMappedData() {
      let sectorId = localStorage.getItem("sectorId");
      let sectorName = localStorage.getItem("sectorName");
      if(sectorName)
      this.titles[1].text = sectorName;
      this.$store.dispatch("sector/getSectorMappedData", {
        sectorId: sectorId,
      });
    },
  }
};
</script>

