<template>
  <div>
    <div class="d-flex align-center px-4 py-2 mt-2">
      <div class="w-100">
        <div class="font-weight-bold primaryColor">API KEY
          <v-progress-circular class="ml-2" indeterminate v-if="loading" size="18" :width="2" color="black">
          </v-progress-circular>
        </div>
        <div class="pt-3 d-flex justify-space-between">
          <div>
            <v-slide-group v-model="tabSelect" mandatory>
              <v-slide-item v-for="n in tabs" :key="n" v-slot="{ active, toggle }" :value="n">
                <span @click="date = null; userId1 = ''">
                  <v-btn :title="n" depressed height="26" class="fsize12 text-capitalize mr-2" @click="toggle" :color="active ? 'primary' : 'grey lighten-3'">{{ n }}</v-btn>
                </span>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </div>
    </div>
    <v-divider class="mx-4"></v-divider>
    <v-form ref="forms" v-model="formValid" @submit.prevent="getApiKeyDetails('get')">
      <v-card class="ma-4 cardBoxShadow rounded-lg" v-if="tabSelect == 'API Key Details'">
        <v-row no-gutters class="px-4 py-10">
          <v-col cols="12" sm="12" md="6" lg="3">
            <div class="fsize14 font-weight-bold primaryColor pb-4">API Key Details</div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="5">
            <div class="primaryColor fsize14 pb-1">*UserId</div>
            <v-text-field clearable class="apiKeyInput" placeholder="Enter UserId" @input="userId = userId ? userId.toUpperCase() : ''" v-model="userId" outlined dense :rules="userIdRules">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
          </v-col>
        </v-row>
        <div class="pa-10 font-weight-bold" v-if="apiKeyDetails">
          <div class="d-flex mb-3" style="min-width:350px;overflow:auto">
            <div class="primaryColor fsize14">User Id : &nbsp;</div>
            <div class="secondaryColor fsize14">{{ apiKeyDetails.user_id }}</div>
          </div>
          <div class="d-flex mb-3" style="min-width:350px;overflow:auto">
            <div class="primaryColor fsize14">API Key : &nbsp;</div>
            <div class="secondaryColor fsize14"> {{ apiKeyDetails.api_key ? apiKeyDetails.api_key : '' }}</div>
          </div>
          <div class="d-flex mb-3" style="min-width:350px;overflow:auto">
            <div class="primaryColor fsize14">Created On : &nbsp;</div>
            <div class="secondaryColor fsize14"> {{ apiKeyDetails.created_on ? `${getDateString(new
                Date(apiKeyDetails.created_on.split(' ')[0]), "d-M-y")} ${apiKeyDetails.created_on.split(' ')[1]}` : ''
            }}
            </div>
          </div>
          <div class="d-flex mb-3" style="min-width:350px;overflow:auto">
            <div class="primaryColor fsize14">Expiry Date : &nbsp;</div>
            <div class="">
              <div class="secondaryColor fsize14"> {{ apiKeyDetails.expiry_date ? getDateString(new
                  Date(apiKeyDetails.expiry_date.split(' ')[0]), "d-M-y") : ''
              }}</div>
            </div>
          </div>
          <div class="d-flex py-4">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field @click:append="dateMenu = true" class="apiKeyInput" v-model="computedDateFormatted" outlined persistent-hint :menu-props="{ bottom: true, offsetY: true }" :rules="dateRules" placeholder="Select the Date" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker :min="new Date().toISOString().substr(0, 10)" v-model="date" :show-current="true" no-title @input="dateMenu = false"></v-date-picker>
              </v-menu>
            </v-form>
            <div class="mx-5">
              <v-btn height="40" :loading="loading" depressed @click="updateApiKey()" class="text-capitalize fsize12">
                Update Date</v-btn>
            </div>
          </div>
        </div>
      </v-card>

      <v-card class="ma-4 cardBoxShadow rounded-lg" v-if="tabSelect == 'Expiry List'">
        <v-form ref="form" v-model="valid" @submit.prevent="getExpiryList()" lazy-validation>
          <div class="d-flex align-center">
            <div class="pa-4">
              <div class="pb-1">*From Date</div>
              <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field @click:append="dateMenu1 = true" class="apiKeyInput" v-model="computedDateFormatted1" outlined persistent-hint :menu-props="{ bottom: true, offsetY: true }" :rules="fromDateRules" placeholder="Select the From Date" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="fromDate" :show-current="true" no-title @input="dateMenu1 = false">
                </v-date-picker>
              </v-menu>
            </div>

            <div class="pa-4">
              <div class="pb-1">*To Date</div>
              <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field @click:append="dateMenu2 = true" class="apiKeyInput" v-model="computedDateFormatted2" outlined persistent-hint :menu-props="{ bottom: true, offsetY: true }" :rules="toDateRules" placeholder="Select the To Date" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker v-model="toDate" :show-current="true" no-title @input="dateMenu2 = false">
                </v-date-picker>
              </v-menu>
            </div>
            <v-btn type="submit" height="40" depressed color="primary" class="text-capitalize fsize14">Submit</v-btn>
          </div>
        </v-form>
        <v-simple-table v-if="ApiKeyExpiryList.length">
          <thead>
            <tr>
              <th>User Id</th>
              <th>Expiry Date</th>
              <th>created On</th>
              <th>API Key</th>
              <!-- <th class="text-center">Action</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idex) in ApiKeyExpiryList" :key="idex" @mouseleave="i.showEdit = false" @mouseover="i.showEdit= true">
              <td>{{ i.user_id }}</td>
              <td>{{ getDateString(new Date(i.expiry_date.split(' ')[0]), "d-M-y") }}</td>
              <td>{{ getDateString(new Date(i.created_on.split(' ')[0]), "d-M-y") }}</td>
              <td class="position-relative">
                {{ i.api_key }}
                <div v-if="i.showEdit" class="position-absolute right-0">
                  <v-btn depressed color="grey" width="fit-content" height="26" class="fsize12 text-capitalize white--text" @click="openDailog(i.user_id)">Update Expiry</v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <div v-else class="d-flex align-center justify-center min-height-100">
          <div class="primaryColor fsize20">No Data Found</div>
        </div>

        <v-dialog v-model="logoutDialog" width="390" overlay-color="white">
          <v-card class="pa-5 cardBoxShadow rounded-lg" height="220">
            <div class="secondaryColor fsize14 d-flex justify-space-between">
              <div class="fsize14 font-weight-bold">Update Expiry Date</div>
              <div>
                <v-icon @click="logoutDialog = false" color="light-blue">mdi-close-circle-outline</v-icon>
              </div>
            </div>
            <div class="mt-4">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="fsize14 pb-1">Date</div>
                <v-menu v-model="dateMenu3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field @click:append="dateMenu3 = true" class="apiKeyInput" v-model="computedDateFormatted3" outlined persistent-hint :menu-props="{ bottom: true, offsetY: true }" :rules="dateRules" placeholder="Select the Date" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="updateExpiryDate" :show-current="true" no-title @input="dateMenu3 = false">
                  </v-date-picker>
                </v-menu>
                <div class="d-flex justify-end pt-3">
                  <v-btn height="32" width="86" @click="updateDate()" depressed outlined text color="light-blue" class="text-capitalize white--text fsize12 mr-2">
                    Confirm
                  </v-btn>
                  <v-btn height="32" width="86" @click="logoutDialog = false" depressed color="black" outlined text class="text-capitalize fsize12">
                    Cancel
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-card>
        </v-dialog>
      </v-card>

      <div class="mt-5 pa-4 d-flex justify-end" v-if="tabSelect == 'API Key Details'">
        <v-btn height="40" width="96" depressed outlined color="grey--text" class=" mr-3 text-capitalize">Cancel</v-btn>
        <v-btn type="submit" :loading="loading" height="40" width="96" depressed color="primary" class="mr-3 text-capitalize">Submit</v-btn>
      </div>

    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import commonFunc from "../mixins/commonFunctions";
export default {
  name: "apiKey",
  mixins: [commonFunc],

  data: () => ({
    tabs: ["API Key Details", "Expiry List"],
    tabSelect: "Reset",
    userId: "",
    userId1: "",
    date: null,
    fromDate: null,
    toDate: null,
    updateExpiryDate: null,
    dateMenu: false,
    dateMenu1: false,
    dateMenu2: false,
    dateMenu3: false,
    userIdRules: [(v) => !!v || "UserId is required"],
    userId1Rules: [(v) => !!v || "UserId is required"],
    dateRules: [(v) => !!v || "Date is required"],
    fromDateRules: [(v) => !!v || "From Date is required"],
    toDateRules: [(v) => !!v || "To Date is required"],
    valid: true,
    formValid: true,
    logoutDialog: false,
    expiryDate: "",
    currentUserId: "",
    showUpdate: false,
    userIdRules: [
      (v) => !!v || "User Id is required",
      (v) =>
        (v && v.length >= 2) || "User Id must be greater than 2 characters",
    ],
  }),

  computed: {
    ...mapGetters("apiKey", {
      apiKeyDetails: "getApiKeyDetails",
      ApiKeyExpiryList: "getApiKeyExpiryList",
    }),
    ...mapGetters({
      loading: "getLoader",
    }),
    computedDateFormatted: {
      get() {
        return this.formatDate(this.date);
      },
      set() {},
    },
    computedDateFormatted1: {
      get() {
        return this.formatDate(this.fromDate);
      },
      set() {},
    },
    computedDateFormatted2: {
      get() {
        return this.formatDate(this.toDate);
      },
      set() {},
    },
    computedDateFormatted3: {
      get() {
        return this.formatDate(this.updateExpiryDate);
      },
      set() {},
    },
  },

  watch: {
    tabSelect(tab) {
      this.$store.commit("apiKey/SET_API_KEY_DETAILS", null);
      this.fromDate = new Date().toISOString().substr(0, 10);
      this.toDate = new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10);
      tab == "Expiry List" ? this.getExpiryList("initial") : "";
    },
  },

  created() {},

  methods: {
    async getApiKeyDetails(type) {
      if (this.$refs.forms.validate()) {
        let formData = new FormData();
        formData.append(
          "userId",
          type == "update" ? this.apiKeyDetails.user_id : this.userId
        );
        await this.$store.dispatch("apiKey/getApiKeyDetails", {
          data: formData,
          type: type,
        });
      }
    },
    async updateApiKey() {
      let formData = new FormData();
      formData.append("userId", this.apiKeyDetails.user_id);
      formData.append("expiryDate", this.date);
      await this.$store.dispatch("apiKey/updateApiKey", formData);
      this.resetUpdateApiKeyForm();
      this.getApiKeyDetails("update");
    },

    resetUpdateApiKeyForm() {
      this.userId1 = "";
      this.date = null;
      this.$refs.form.resetValidation();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getExpiryList(val) {
      if (val == "initial") {
        let fd = new FormData();
        fd.append("from_date", this.fromDate);
        fd.append("to_date", this.toDate);
        this.$store.dispatch("apiKey/getApiExpiryList", fd);
      } else if (this.$refs.form.validate()) {
        let fd = new FormData();
        fd.append("from_date", this.fromDate);
        fd.append("to_date", this.toDate);
        this.$store.dispatch("apiKey/getApiExpiryList", fd);
      }
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    openDailog(val) {
      this.$refs.form.reset();
      if (val) {
        this.logoutDialog = true;
      }
      this.currentUserId = val;
    },

    async updateDate() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("expiryDate", this.updateExpiryDate);
        formData.append("userId", this.currentUserId);
        await this.$store.dispatch("apiKey/updateApiKey", formData);
        this.getExpiryList();
        this.resetUpdateApiKeyForm();
        this.logoutDialog = false;
      }
    },
    
  },
};
</script>

<style>
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.right-0 {
  right: 24px;
  top: 20%;
}
</style>